import classNames from "classnames";
import React, { ReactNode } from "react";
import Text from "../Text/Text";
import styles from "./InfoBadge.module.scss";

type InfoBadgeColor = "blue" | "green" | "pink" | "black";

interface InfoBadgeProps {
  name: ReactNode;
  color: InfoBadgeColor;
}

const InfoBadge = ({ name, color }: InfoBadgeProps) => {
  return (
    <div className={classNames(styles.root, styles[color])}>
      <Text variant="default">{name}</Text>
    </div>
  );
};

export default InfoBadge;
