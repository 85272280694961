import React from "react";
import {
  subjectsFromUnit,
  SanityUnitOfWork,
  SanityLesson
} from "../../model/lessons";

import GetText from "../../i18n/GetText";
import sortBy from "lodash.sortby";
import LevelIndicator from "../LevelIndicator/LevelIndicator";
import TagInfo from "../../components/TagInfo/TagInfo";
import InfoBadge from "../InfoBadge/InfoBadge";
import styles from "./EducationTagInfo.module.scss";
import { separateBy } from "../../utils/separate-by";
import { AgeRange, ProgrammingLanguage } from "../../model/common";
import { SanityMake } from "../../model/makes";
import { SanityPlaygroundSurveyGuide } from "../../model/playground-survey-guide";

export const MakeTagInfo = ({
  gutterBottom,
  value,
  className
}: {
  gutterBottom?: boolean;
  value: SanityMake;
  className?: string;
}) => {
  const { programmingLanguages, level } = value;
  const levelParts = [<LevelIndicator key={level} value={level} />];
  const programmingLanguagesParts = programmingLanguages.map(
    (id: ProgrammingLanguage) => <GetText key={id} id={id} />
  );
  const deviceFeatureParts = sortBy(
    value.deviceFeatures,
    s => s.name
  ).map(deviceFeature => (
    <span key={deviceFeature._id}>{deviceFeature.name?.trim()}</span>
  ));
  const topicParts = sortBy(value.topics, t => t.name).map(topic => (
    <span key={topic._id}>{topic?.name?.trim()}</span>
  ));
  const input = [
    levelParts,
    programmingLanguagesParts,
    deviceFeatureParts,
    topicParts
  ];
  return (
    <TagInfo className={className} gutterBottom={gutterBottom} value={input} />
  );
};

export const UnitTagInfo = ({
  gutterBottom,
  unit,
  lesson,
  asBadges = false,
  className
}: {
  gutterBottom?: boolean;
  unit: SanityUnitOfWork;
  lesson?: SanityLesson;
  asBadges?: boolean;
  className?: string;
}) => {
  const { programmingLanguages, ageRange, contents } = unit;
  const ageParts = ageRange.map((id: AgeRange) => <GetText key={id} id={id} />);
  const subjectParts = sortBy(
    subjectsFromUnit(unit),
    s => s.name
  ).map(subject => <span key={subject._id}>{subject.name}</span>);
  const programmingLanguagesParts = programmingLanguages.map(
    (id: ProgrammingLanguage) => <GetText key={id} id={id} />
  );
  const input = [subjectParts, programmingLanguagesParts, ageParts];
  if (asBadges) {
    return (
      <div className={styles.badges}>
        {!lesson && (
          <InfoBadge
            name={`${contents.length} ${
              unit.unitType === "designChallenge" ? "activities" : "lessons"
            }`}
            color="black"
          />
        )}
        <InfoBadge
          name={separateBy(programmingLanguagesParts, ", ")}
          color="black"
        />
        <InfoBadge name={separateBy(ageParts, ", ")} color="black" />
      </div>
    );
  }
  return (
    <TagInfo className={className} gutterBottom={gutterBottom} value={input} />
  );
};

export const PlaygroundSurveyGuideTagInfo = ({
  gutterBottom,
  value,
  className
}: {
  gutterBottom?: boolean;
  value: SanityPlaygroundSurveyGuide;
  className?: string;
}) => {
  const { programmingLanguages, ageRange } = value;
  const ageParts = ageRange.map((id: AgeRange) => <GetText key={id} id={id} />);
  const programmingLanguagesParts = programmingLanguages.map(
    (id: ProgrammingLanguage) => <GetText key={id} id={id} />
  );
  const deviceFeatureParts = sortBy(
    value.deviceFeatures,
    s => s.name
  ).map(deviceFeature => (
    <span key={deviceFeature._id}>{deviceFeature.name?.trim()}</span>
  ));
  const topicParts = sortBy(value.topics, t => t.name).map(topic => (
    <span key={topic._id}>{topic?.name?.trim()}</span>
  ));
  const input = [
    ageParts,
    programmingLanguagesParts,
    deviceFeatureParts,
    topicParts
  ];
  return (
    <TagInfo className={className} gutterBottom={gutterBottom} value={input} />
  );
};
