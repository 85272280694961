import React, { ReactNode } from "react";
import { ContentTranslationSponsor, SanityLicence } from "../../model/common";
import BlockContent, { BlockContentProps } from "../BlockContent/BlockContent";
import Credits from "../Credits/Credits";

interface CreditedContentProps extends BlockContentProps {
  licence?: SanityLicence;
  translationSponsors?: ContentTranslationSponsor[];
  // Extra content rendered after the block content.
  children?: ReactNode;
}

/**
 * Block content with license and sponsorship details.
 *
 * Suitable for the main content card in a page.
 */
const CreditedContent = ({
  licence,
  translationSponsors,
  children,
  ...props
}: CreditedContentProps) => {
  return (
    <>
      <BlockContent {...props} />
      {children}
      <Credits translationSponsors={translationSponsors} licence={licence} />
    </>
  );
};

export default CreditedContent;
