import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import BlockContentOutline from "../components/BlockContentOutline/BlockContentOutline";
import Card from "../components/Card/Card";
import CreditedContent from "../components/CreditedContent/CreditedContent";
import EditorEmbed from "../components/EditorEmbed/EditorEmbed";
import EditorEmbedSupport from "../components/EditorEmbedSupport/EditorEmbedSupport";
import { PlaygroundSurveyGuideTagInfo } from "../components/EducationTagInfo/EducationTagInfo";
import Link from "../components/Link/Link";
import Text from "../components/Text/Text";
import GetText from "../i18n/GetText";
import ContentLayout from "../layouts/ContentLayout/ContentLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  MicrobitCode,
  SanityCampaign,
  SanityPage
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SanityPlaygroundSurveyGuide } from "../model/playground-survey-guide";
import { SiteArea } from "../utils/analytics";
import styles from "./PlaygroundSurveyGuidePage.module.scss";

export const pageQuery = graphql`
  query PlaygroundSurveyGuidePage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    playgroundSurveyGuide: sanityPlaygroundSurveyGuide(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      fields {
        editorLinks
      }
      slug {
        current
      }
      ageRange
      topics {
        _id
        name
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      programmingLanguages
      _rawContent(resolveReferences: { maxDepth: 7 })
      deviceFeatures {
        _id
        name
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "playgroundSurveyGuides" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const PlaygroundSurveyGuide = ({
  pageContext,
  data: { menus, playgroundSurveyGuide, campaigns },
  location
}: PlaygroundSurveyGuideProps) => {
  const {
    title,
    _rawContent,
    licence,
    translationSponsors
  } = playgroundSurveyGuide;
  const customEmbeds = {
    microbitCode: ({ node }: { node: MicrobitCode }) => (
      <EditorEmbed
        unit={undefined}
        code={node}
        context={playgroundSurveyGuide}
      />
    )
  };

  return (
    <EditorEmbedSupport document={playgroundSurveyGuide}>
      <PageLayout
        siteArea={SiteArea.PLAYGROUND_ACTIVITY_CODING_GUIDES}
        metadata={{
          title,
          alternates: pageContext.alternates,
          page: playgroundSurveyGuide
        }}
        menus={menus}
        strings={pageContext.strings}
        location={location}
      >
        <ContentLayout
          campaigns={campaigns.nodes}
          title={title}
          tags={<PlaygroundSurveyGuideTagInfo value={playgroundSurveyGuide} />}
          type={<GetText id="coding-guide" />}
          breadcrumbs={
            <Link
              to="http://www.bbc.co.uk/teach/microbit/playground-survey/zrnqp9q"
              className={styles.back}
            >
              <Text variant="breadcrumb" as="span">
                <GetText id="back-to-bbc-teach" />
              </Text>
            </Link>
          }
          aside={
            <nav>
              <BlockContentOutline showOnMobile content={_rawContent} />
            </nav>
          }
          content={
            <Card type="main">
              <CreditedContent
                content={_rawContent}
                customEmbeds={customEmbeds}
                cap="3/4"
                licence={licence}
                translationSponsors={translationSponsors}
              ></CreditedContent>
            </Card>
          }
        />
      </PageLayout>
    </EditorEmbedSupport>
  );
};

export default PlaygroundSurveyGuide;

interface PlaygroundSurveyGuideProps {
  data: {
    menus: GlobalMenus;
    listings: SanityPage;
    playgroundSurveyGuide: SanityPlaygroundSurveyGuide;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
