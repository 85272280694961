import React, { ReactNode } from "react";
import { Row } from "../../components/Grid";
import styles from "./ContentLayout.module.scss";

import SocialLinks from "../../components/SocialLinks/SocialLinks";
import { SpacedCol } from "../../components/Spacing/Spacing";
import Text from "../../components/Text/Text";
import { SanityCampaign } from "../../model/common";
import TwoColumnLayout from "../TwoColumnLayout/TwoColumnLayout";

interface ContentLayoutProps {
  aside: ReactNode;
  content: ReactNode;
  // Typically Breadcrumbs
  breadcrumbs: ReactNode;
  tags: ReactNode;
  title: string;
  type: ReactNode;
  campaigns: SanityCampaign[];
}

/**
 * Layout used for educational content such as projects, design activities and lessons.
 */
const ContentLayout = ({
  aside,
  campaigns,
  content,
  breadcrumbs,
  tags,
  title,
  type
}: ContentLayoutProps) => (
  <TwoColumnLayout
    className={styles.root}
    campaigns={campaigns}
    topRows={
      <Row justify="center">
        <SpacedCol xs={12} sm={10}>
          {breadcrumbs}
          <Text className={styles.type} variant="defaultTiny">
            {type}
          </Text>
          <div className={styles.titleRow}>
            <Text variant="h1">{title}</Text>
            <SocialLinks title={title} />
          </div>
          {tags}
        </SpacedCol>
      </Row>
    }
    aside={aside}
    main={content}
  />
);

export default ContentLayout;
